const jQuery = require('jquery');
const slick = require('slick-carousel');

var $j = jQuery.noConflict();


$j(document).ready(function() {
  // smooth scroll from external anchor, im keeping just in case, but its annoying
  // if(location.hash !== ''){
  //   smoothScrollOnLoad();
  // }

  onClickLoadMoreButton();
  mobileMenuToggle();
  updateMenuColor();
  smoothScroll();

  // initSlick();
  initSlickWorkshops();
  initSlickPartnersforgood();
  initSlickinitiatives();
  initSlickResourcesClaimYour();
  initSlickResourcesPastInitiative();

  navigation();

  accordion();
  playYTVideo();
  //changePushState(''); -> go home

  videoModalEvents();

  animateBlocks(); // when visible on viweport

  $j('.menu-toggle').off().on('click', function (e) {
    e.stopPropagation();
    e.preventDefault();

    $j(this).toggleClass("active");
    $j(".site-header__nav").toggleClass("active");
    return false;
  });

  $j(window).on("resize", function () {
    var mH = 0;
    $j("#resources-pastinitiative .col-slider").each(function () {
      $j(this).css("height", "auto");
      mH = Math.max(mH, $j(this).height());
    });
    $j("#resources-pastinitiative .col-slider").each(function () {
      $j(this).height(mH);
    });
  });
  setTimeout(function() { $j(window).trigger("resize") }, 1000);

});


// - - - Add a smoothScroll for anchor nav links - - -
function smoothScroll() {
  $j(document).on('click', 'a[href^="/#"]', function (event) {

    var hash = this.hash

    $j(".menu-item a").removeClass("active");
    $j(this).addClass("active");

    if ($j(window).width() < 768) {
      $j('html, body').animate({
        scrollTop: $j(hash).offset().top - 120
      }, 500);
      $j(".main-navigation").removeClass("active");
    } else {
      $j('html, body').animate({
        scrollTop: $j(hash).offset().top - 150
      }, 500);
    }
  });
}


// - - - Smooth scroll on external anchor link, its annoying but im keeping in case - - -
function smoothScrollOnLoad() {
  // var hash = location.hash;
  // $j('a[href ^= "/' + hash + '"]').addClass('active');

  // $j().addClass("active");

  // if ($j(window).width() < 768) {
  //   $j('html, body').animate({
  //     scrollTop: $j(hash).offset().top - 120
  //   }, 500);
  //   $j(".main-navigation").removeClass("active");
  // } else {
  //   $j('html, body').animate({
  //     scrollTop: $j(hash).offset().top - 150
  //   }, 500);
  // }
}


// - - - Navigation - - -
function navigation(){
  $j('.main-navigation a:not([href^="#"])').click(function(e){
    e.preventDefault();
    e.stopPropagation();
    changePushState($(this).attr('href'));
  });
}


// - - - Chnage Push State - - -
function changePushState(page){
  window.history.pushState( baseURL, page, baseURL+page);
}


// - - - Animate blocks - - -
function animateBlocks() {
  $j('.site-body').on("scroll touchstart resize", function () {
    $j(".js-animated-block").each(function () {

      // ----- same shift all the time ------
      //addAnimationClassIfInViewport($j(this), 400);

      // belows are examples.
      // Use as many if as required or simply stay with basic shift for all animations like above
      // do not use 2 diff if else. All must be under the same if else

      // ----- mobile VS desktop example ------
       if (window.innerWidth > 767) {
        addAnimationClassIfInViewport($j(this), 400)
      } else {
        addAnimationClassIfInViewport($j(this), 800);
      }

      // ----- diff page example ------
      /* if ( $j(this).hasClass("latest-feed")) {
        addAnimationClassIfInViewport($j(this), 300)
      } else {
        addAnimationClassIfInViewport($j(this), 400);
      }*/

    })
  }).trigger("resize");
}


// - - - Check if element is in viewport - - -
function isInViewport(elem, shift) {
  var elementTop = elem.offset().top + shift;
  var elementBottom = elementTop + elem.outerHeight();
  var viewportTop = $j(window).scrollTop();
  var viewportBottom = viewportTop + $j(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};


// - - - Add class to animated block - - -
function addAnimationClassIfInViewport(elem, shift) {
  if (isInViewport(elem, shift)) {
    elem.addClass("animated-block");
  } else {
    //elem.removeClass("animated-block");  // <- comment this out to remove the reverse animation
  }
}


// - - - Init all slick - - -
function initSlick(){
  $j('.slick-slider').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    dots : true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });
}


// - - - Init Slick Workshops- - -
function initSlickWorkshops(){
  $j('.workshops .slick-slider').slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots : true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });
}

// - - - Init Slick Partners for good- - -
function initSlickPartnersforgood(){
  $j('.partnersforgood .slick-slider.slider-images').slick({
    infinite: false,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    dots : true,
    arrows: true,
    appendArrows: $j('.partnersforgood .arrows'),
    asNavFor: '.partnersforgood .slick-slider.slider-texts',
    responsive: [
      {
	      breakpoint: 767,
	      settings: {
	        dots : false,
          slidesToShow: 1,
	      }
	    },
	    {
	      breakpoint: 1000,
	      settings: {
	        dots : false,
          slidesToShow: 1.1,
	      }
	    },
      {
	      breakpoint: 1200,
	      settings: {
	        dots : false,
          slidesToShow: 1.2,
	      }
	    }
	  ]
  });

  $j('.partnersforgood .slick-slider.slider-texts').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots : false,
    arrows: false,
    fade: true,
    asNavFor: '.partnersforgood .slick-slider.slider-images',
  });
}


// - - - Init Slick Initiatives- - -
function initSlickinitiatives(){
  $j('.past-initiatives .slick-slider.slider-images').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "300px",
    infinite: true,
    dots : false,
    arrows: true,
    variableWidth: true,
    appendArrows: $j('.past-initiatives .arrows'),
    asNavFor: '.past-initiatives .slick-slider.slider-texts',
    responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        centerPadding: '0px',
	        dots : true
	      }
	    }
	]
  });

  $j('.past-initiatives .slick-slider.slider-texts').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    infinite: true,
    dots : true,
    arrows: false,
    fade: true,
    asNavFor: '.past-initiatives .slick-slider.slider-images',
    responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        dots : false
	      }
	    }
	  ]
  });

  $j('.past-initiatives .slick-slider').on('init', function(event, slick, currentSlide, nextSlide){
	$j(window).trigger("resize");
  });
  $j('.past-initiatives .slick-slider img').on('load', function(event){
	$j(window).trigger("resize");
  });

  $j(window).on("resize", function () {
    var dots_width = $j(".slick-dots").width();
    var image_width = $j(".slider-images .slick-current").width();
    var image_height = $j(".slider-images .slick-current").height();
    if ($j(window).width() < 1000) {
    	$j(".past-initiatives .arrows").width("100%").css("top", 48);
    } else {
    	$j(".past-initiatives .arrows").width(image_width + 60).css("top", image_height/2 + 36);
    }
    
  }).trigger("resize");
}

// - - - Init Slick Resources Claim Your- - -
function initSlickResourcesClaimYour(){
  $j('.resources-claimyour .slick-slider.slider-images').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots : true,
    arrows: true,
    appendArrows: $j('.resources-claimyour .arrows'),
    asNavFor: '.resources-claimyour .slick-slider.slider-texts',
    responsive: [
	    {
	      breakpoint: 1000,
	      settings: {
	        dots : false,
          slidesToShow: 1,
	      }
	    }
	  ]
  });

  $j('.resources-claimyour .slick-slider.slider-texts').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots : false,
    arrows: false,
    fade: true,
    asNavFor: '.resources-claimyour .slick-slider.slider-images',
  });
}

// - - - Init Slick Resources Past Initiative- - -
function initSlickResourcesPastInitiative(){
  $j('.resources-pastinitiative .slick-slider.slider-images').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots : true,
    arrows: true,
    appendArrows: $j('.resources-pastinitiative .arrows'),
    responsive: [
	    {
	      breakpoint: 1450,
	      settings: {
	        dots : true,
          slidesToShow: 2.5,
	      }
	    },
	    {
        breakpoint: 1200,
        settings: {
          dots : true,
          slidesToShow: 2.2,
        }
      },
      {
        breakpoint: 1050,
        settings: {
          dots : true,
          slidesToShow: 2,
        }
      },
      {
	      breakpoint: 769,
	      settings: {
	        dots : true,
          slidesToShow: 1.1,
          appendDots: $j('.resources-pastinitiative .dots'),
          infinite: false,
	      }
	    }
	  ]
  });

}

// - - - Accordion - - -
function accordion(){
  $j('.accordion-title').on('click',function(e) {
    e.preventDefault();
    var ctn = $j(this).parents('.accordion');
    console.log(ctn);
    ctn.toggleClass('expanded');
    ctn.find('.content').slideToggle(500);
  });
}


// - - - Play hero banner video when clicking on the hero banner img - - -
function playYTVideo() {
  $j(".js-video-banner-img").on("click", function(e) {
    if ($j("body").hasClass("--cookie-optout")) { return true; }
    e.stopPropagation();
    e.preventDefault();

    var iframe = $j(this).closest('.js-hero-banner').find('iframe');
    iframe[0].src += "&autoplay=1";
    $j(this).hide();
  })
}

// - - - Video Modal Events - - -
function videoModalEvents(){
  $j('.js-video-modal').click(function(e){
    if ($j("body").hasClass("--cookie-optout")) { return true; }
    e.stopPropagation();
    e.preventDefault();

    var videoURL = $j(this).attr('data-url');
    var videoOrientation = $j(this).attr('data-orientation');
    $j('.modal__video-ctn .video-container').html('<iframe src="https://www.youtube.com/embed/'+videoURL+'?enablejsapi=1&rel=0&playlist='+videoURL+'&autoplay=1" title="YouTube video player" frameborder="0" allow="autoplay; accelerometer; encrypted-media; gyroscope;" allowfullscreen modestbranding></iframe>');
    $j('.modal').addClass('-active');

    if (videoOrientation === 'portrait') {
      $j('.modal').addClass('-portrait');
    }
  })

  $j('.modal__bg,.modal__close-btn').click(function(){
    $j('.modal').removeClass('-active');
    $j('.modal__video-ctn .video-container').html('');
    $j('.stay-connected').focus();

    if ($j('.modal').hasClass('-portrait')) {
      $j('.modal').removeClass('-portrait');
    }
  })
} 

function updateMenuColor () {
  let  currentPage = $j('.site-body').attr('data-page');
  let menuItems = document.querySelectorAll('.menu__item');

  for (let i = 0; i < menuItems.length; i++) {
    let menuLink = menuItems[i].querySelector('a');
    if (menuLink.getAttribute('data-page') === currentPage) {
      menuLink.classList.add('is-active');
    } 
  }
}

function mobileMenuToggle() {
  $j('.menu__toggle').click(function(){
    $j('.site-header__menu-ul').toggleClass('open');
  })
}

function onClickLoadMoreButton() {
  $j('.js-load-more-btn').click(function() {
    $j(this).hide();
    updateLoadMoreItemDisplay();
  });
}

function updateLoadMoreItemDisplay() {
  $j('.js-load-more-items').addClass("--show");
}
